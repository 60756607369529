import {createApp, provide, h} from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import { createPinia } from 'pinia'


import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import {vMaska} from "maska"

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';

import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'

import _ from 'lodash';

import moment from 'moment'

import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { ApolloClients } from '@vue/apollo-composable'

const pinia = createPinia()

const cache = new InMemoryCache()

export const apolloClientAccount = new ApolloClient({
  cache,
  uri: 'https://roscia.tech/api/graphql',
})
const apolloClientClient = new ApolloClient({
  cache,
  uri: 'https://roscia.tech/api/v1/clients/graphql',
})

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

let app = createApp({
  setup () {
    provide(ApolloClients, {
        default: apolloClientAccount,
        Client: apolloClientClient
    })
  },

  render: () => h(App),
})

app.use(store)
    .use(pinia)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .component(VueFeather.type, VueFeather)
    .directive("maska", vMaska)
    .use(i18n)
    .use(vClickOutside)

app.config.globalProperties.$lodash = _;
app.config.globalProperties.$moment = moment;

app.mount('#app');