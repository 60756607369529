import {defineStore} from 'pinia'
import {provideApolloClients, useQuery} from "@vue/apollo-composable";
import gql from "graphql-tag";
import {apolloClientAccount} from "@/main";
import {computed} from "vue";



export const useStore = defineStore('userStore', {
    state: () => ({
        user: null,
        company: null,
        sessionId: null,
        loading: false,
        error: null,
    }),
    actions: {
        authentication(email, company) {
            this.user = email
            this.company = company
        },
        async isAuthentified() {
            if (this.sessionId === null) {
                if (sessionStorage.getItem('sessionId') === null) {
                    return false
                }
                this.sessionId = sessionStorage.getItem('sessionId')

                const query = provideApolloClients({default: apolloClientAccount})(() => useQuery(gql`
                    query getUserInformation {
                    getUserInformation {
                      userEmail
                      companyName
                    }
                  }
                `, null, {
                    clientId: "default",
                  fetchPolicy: 'no-cache',
                }))
                this.user =  computed(() => query.result.value?.getUserInformation.userEmail)
                this.company = computed(() => query.result.value?.getUserInformation.companyName)

                return true
            }
            return true
        }
    }
})
