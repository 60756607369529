import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/ecommerce/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: "/clients/:key",
    name: "clients",
    meta: { title: "Clients", authRequired: true },
    component: () => import("../views/pages/client/main0.vue"),
  },
  {
    path: "/clients",
    name: "client-list",
    meta: { title: "List Clients", authRequired: true },
    component: () => import("../views/pages/client/main-list.vue"),
  },
  {
    path: "/quotes",
    name: "quotes",
    meta: { title: "Devis", authRequired: true },
    component: () => import("../views/pages/business-parts/main.vue"),
  },
  {
    path: "/quotes/create",
    name: "quotesCreate",
    meta: { title: "Creation devis", authRequired: true },
    component: () => import("../views/pages/business-parts/create.vue"),
  },
  {
    path: "/products",
    name: "products",
    meta: { title: "Produit", authRequired: true },
    component: () => import("../views/pages/product/list.vue"),
  },
  {
    path: "/products/details",
    name: "productsDetails",
    meta: { title: "Detail Produit", authRequired: true },
    component: () => import("../views/pages/product/main.vue"),
  }
];