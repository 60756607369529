<template>
  <router-view></router-view>

</template>

<script>

export default {
  name: 'App',
  components: {},
  methods: {
    adjustZoom() {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1400 && screenWidth < 2000) {
        document.body.style.zoom = "80%";
        document.screen
      } else {
        document.body.style.zoom = "100%";
      }
    }
  },
  // mounted() {
  //   this.adjustZoom();
  //   window.addEventListener('resize', this.adjustZoom);
  // },
  // beforeUnmount() {
  //   window.removeEventListener('resize', this.adjustZoom);
  // }
}
</script>
